/**
 * Returns a Promise that resolves after a specified amount of time.
 * @param {number} ms - The time in milliseconds to delay the promise.
 * @returns {Promise<void>} A promise that resolves after the specified time.
 */
export function delay (ms: number): Promise<void> {
    return new Promise((res: () => void) => setTimeout(res, ms));
}

/**
 * Returns an empty string if the input parameter is undefined, otherwise returns the input string.
 * @param {string|undefined} input - The input parameter to check.
 * @returns {string} An empty string or the input string.
 */
export function ifUndefinedEmptyString (input: string | undefined): string {
    return input === undefined ? '' : input;
}
  
/**
 * Returns 0 if the input parameter is undefined, otherwise returns the input number.
 * @param {number|undefined} input - The input parameter to check.
 * @returns {number} 0 or the input number.
 */
export function ifUndefinedZero (input: number | undefined): number {
return input === undefined ? 0 : input;
}

/**
 * Returns new array if the input parameter is undefined, otherwise returns the input array.
 * @param {Array<T>|undefined} input - The input parameter to check.
 * @returns {Array<T>} Array<T> or the input number.
 */
export function ifUndefinedNewArray<T> (input: Array<T> | undefined): Array<T> {
return input === undefined ? new Array<T>() : input;
}

/**
 * Returns an empty string if the input parameter is null, otherwise returns the input string.
 * @param {string|null} input - The input parameter to check.
 * @returns {string} An empty string or the input string.
 */
export function ifNullEmptyString (input: string | null): string {
return input == null ? '' : input;
}

/**
 * Returns 0 if the input parameter is null, otherwise returns the input number.
 * @param {number|null} input - The input parameter to check.
 * @returns {number} 0 or the input number.
 */
export function ifNullZero (input: number | null): number {
    return input == null ? 0 : input;
}