import React, { useEffect, useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Pie } from 'react-chartjs-2';


import './App.css';
import APIService from './services/APIService';
import { delay } from './utils/CommonFunctions';
import { Vote } from './models/Vote';

const App = () => {
  const buildPiData = (boy?: number, girl?: number, weq?: number) : ChartData<"pie"> => {
    let _boy = boy || 0;
    let _girl = girl || 0;
    let _weq = weq || 0;
    return {
      labels: ['Boy', 'Girl', 'Weequay'],
      datasets: [
        {
          label: ' Votes',
          data: [_boy, _girl, _weq],
          backgroundColor: [
            'rgb(102, 163, 247)',
            'rgb(221, 116, 185)',
            'rgb(193, 154, 107)',
          ],
          borderColor: [
            'rgba(255, 255, 255, 1)',
            'rgba(255, 255, 255, 1)',
            'rgba(255, 255, 255, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  }

  const apiService: APIService = new APIService();
  const [showBanner, setShowBanner] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [showCastVote, setShowCastVote] = useState(false);
  const [showVoteResults, setShowVoteResults] = useState(false);
  const [votes, setVotes] = useState<Array<Vote>>(new Array<Vote>());
  const [name, setName] = useState("");
  const [nameIsValid, setNameIsValid] = useState<boolean | undefined>();
  const [gender, setGender] = useState("");
  const [genderIsValid, setGenderIsValid] = useState<boolean | undefined>();
  const [piData, _setPiData] = useState<ChartData<"pie">>(buildPiData());

  ChartJS.register(ArcElement, Tooltip, Legend);
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          color: "white",
        }
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  
  const setPiData = (votes: Array<Vote>) => {
    let _girlCount = 0;
    let _boyCount = 0;
    let _weq = 0;
    votes.forEach((v) => {
      if(v.gender === "g") {
        _girlCount++;
        return;
      }
      if(v.gender === "b") {
        _boyCount++;
        return;
      }
      if(v.gender === "w") {
        _weq++;
      }
      
    });
    _setPiData(buildPiData(_boyCount, _girlCount, _weq));
  }

  useEffect(() => {
    fadeOutBanner();
    
  }, []);

  const fadeOutBanner = async(): Promise<void> => {
    //let _voted = localStorage.getItem("voted");
    let _voted = "true";
    if(_voted ==="true") {
      getVoteResults();
    }
    await delay(4000);
    setFadeOut(true);
    await delay(2000);
    setShowBanner(false);
    setFadeOut(false);
    if(_voted === "true") {
      setShowVoteResults(true);
    } else {
      setShowCastVote(true);
    }
    
  }

  const castVote = async(): Promise<void> => {
    let valid = await validateName();
    valid = (valid) && await validateGender();
    if(valid) {
      let result = await apiService.PostVote(name, gender);
      localStorage.setItem("voted", "true");
      if(result.success) {
        getVoteResults();
      }
      setFadeOut(true);
      await delay(2000);
      setShowCastVote(false);
      setFadeOut(false);
      setShowVoteResults(true);
    } else {
      console.log("Invalid");
    }
  }

  const getVoteResults = async(): Promise<void> => {
    let _votes = await apiService.GetVotes();
    let __votes = _votes?.votes || new Array<Vote>();
    setVotes(__votes);
    setPiData(__votes);
  }

  const validateName = async(): Promise<boolean> => {
    if(name.length > 0) {
      setNameIsValid(true);
      return true;
    } else {
      setNameIsValid(false);
      return false;
    }
  }

  const validateGender = async(): Promise<boolean> => {
    if(gender.length > 0) {
      setGenderIsValid(true);
      return true;
    } else {
      setGenderIsValid(false);
      return false;
    }
  }
  
  return (
    <div className="App bg-gradient-to-b from-blue-400 to-pink-400 flex flex-col h-screen v-screen items-center justify-center">
      {showBanner &&
        <div className={`animate-fadeIn2x text-white text-4xl font-semibold ${(fadeOut ? ' animate-fadeOut ' : '')}` }>Thanks for Voting</div>
      }
      {showCastVote && 
        <div className={`animate-fadeIn2x bg-gradient-to-b from-pink-400 to-blue-400 flex flex-col items-center rounded-lg p-12 ${(fadeOut ? ' animate-fadeOut ' : '')}`}>
          <div className="questionImage items-center mb-4 flex items-center"><img src="ultrasound.jpg" className="rounded-2xl"></img></div>
          <div className="InputText">
            <label id="name" className="relative block">
            <span className="text-violet-100 font-semibold text-xl">Your name:</span>
            <input className={`input-text mt-1 ${nameIsValid === false ? 'outline outline-red-500 ring-2 ring-red-500 ring-inset focus:outline focus:outline-red-500 ' : ''}`} 
                onChange={(e): void => {if(nameIsValid === false) {setNameIsValid(true);} setName(e.target.value);}} 
                onBlur={() => {validateName();}}
                placeholder="First Last">
            </input>
            </label>
          </div>
          <div className={`InputRadioGroup flex-col justify-items-start border-2 ${genderIsValid === false ? 'border-red-500' : 'border-cyan-500'} rounded-lg mt-6 p-4`}>
            <fieldset>
            <div className="mb-2">
            <input type="radio" id="boy" name="gender" onChange={(e): void => {setGenderIsValid(true); setGender(e.target.id);}} />
            <label className="ml-2 text-violet-100 font-semibold">Boy</label>
            </div>
            <div>
            <input type="radio" id="girl" name="gender" onChange={(e): void => {setGenderIsValid(true); setGender(e.target.id);}} />
            <label className="ml-2 text-violet-100 font-semibold">Girl</label>
            </div>
            </fieldset>
          </div>
          <div className="SubmitButton mt-6">
            <div className="text-2xl font-bold text-gray-100 rounded-lg bg-violet-600 m-2 p-2 border-2 border-violet-700 hover:bg-violet-700 hover:border-violet-600 cursor-pointer drop-shadow-sm" onClick={castVote}>Cast My Vote</div>
          </div>
        </div>
      }
      {showVoteResults && 
        <div className={`animate-fadeIn2x bg-gradient-to-b from-pink-400 to-blue-400 flex flex-col items-center rounded-lg p-12 ${(fadeOut ? ' animate-fadeOut ' : '')}`}>
          <div className="text-2xl font-bold text-gray-100 mb-2">Total Votes: {votes.length}</div>
          <Pie data={piData} options={options} />
          <div className="NameList mt-6 bg-slate-200 w-full rounded-xl max-h-20 h-20 overflow-y-scroll">
            {votes.map((v) => {
              return(
              <div className={`pt-2 ${(v.gender === "b" ? "bg-blue-400" : (v.gender === "g") ? "bg-pink-400" : "bg-sand")}`}>
                {v.name}
              </div>
              );
            })}
          </div>
      </div>
      }
    </div>
  );
}

export default App;
