export class APIError extends Error {
    code: number;
    body?: string;
    private __proto__: any;
  
    constructor (msg: string, code: number, body?: string) {
      super(msg);
  
      Object.setPrototypeOf(this, new.target.prototype);
      this.code = code;
      this.body = body;
    }
  }
  