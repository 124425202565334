import { APIError } from "../error/APIError";
import Globals from "../globals/Globals";
import { VoteResultsResponse } from "../response/VoteResultsResponse";
import { ResponseBase } from "../response/base/ResponseBase";
import { ifUndefinedEmptyString } from "../utils/CommonFunctions";
import ModelMapperService from "./ModelMapperService";

export interface IAPIHandler {
    url: string,
    method: string,
    headers?: any,
    body?: any,
    returnResponse: any,
    mappingFunction?: (json: string) => void,
  }

export default class APIService {
    constructor(

    ){}

    public async GetVotes(): Promise<VoteResultsResponse> {
      const method = 'GET';
      const url = `${Globals.BASE_API_URL}vote`;

      return await this.APIHandler({
          url: url,
          method: method,
          returnResponse: new VoteResultsResponse()
      });
  }

    public async PostVote(name: string, gender: string): Promise<ResponseBase> {
        const method = 'POST';
        const url = `${Globals.BASE_API_URL}vote`;
        const body = JSON.stringify({'name': name, 'gender': gender});

        return await this.APIHandler({
            url: url,
            method: method,
            body: body,
            returnResponse: new ResponseBase()
        });
    }

    static handleResponse = async (response: Response): Promise<any> => {
        if (!response.ok) {
          try {
            const body = await response.text();
            return new APIError(response.statusText.toString(), response.status, body);
          } catch {
            return new APIError('Unexpected Error', 0, '');
          }
    
        } else {
          return await response.json();
        }
      };

    /* *******************************************************************************************************************
                      Everything API Handler
    **********************************************************************************************************************/
    private async APIHandler<T extends ResponseBase> (props: IAPIHandler): Promise<T> {

    await fetch(props.url, {
      method: props.method,
      ...(props.headers === undefined &&
      {
        headers: {
          ...(props.body !== undefined && props.body !== null && { 'Content-Type': 'application/json' })
        }
      }),
      ...(props.headers !== undefined && { 'headers': props.headers }),
      ...(props.body !== undefined && props.body !== null &&
      {
        'body': props.body
      })
    })
      .then(async (response) => {
        if (response.status === 204) { return props.returnResponse; }
        return await APIService.handleResponse(response);
      })
      .then((responseJson) => {
        if (responseJson instanceof APIError) {
          throw responseJson;
        }
        if (props.mappingFunction !== undefined) {
          props.returnResponse = props.mappingFunction(responseJson);
        } else {
          props.returnResponse = ModelMapperService.autoMapperMapAnythingFromAPI(props.returnResponse, responseJson);
        }

        if (!props.returnResponse.success) {
          throw new APIError(props.returnResponse.message ?? '', props.returnResponse.errorCode ?? -1);
        }
      })
      .catch(async (error) => {
        props.returnResponse.success = false;
        if (error instanceof APIError) {
          const friendlyResponse = await APIService.ResponseErrorHandler(error);
          props.returnResponse.message = friendlyResponse.message;
          props.returnResponse.header = friendlyResponse.header;
          props.returnResponse.errorCode = error.code;
          return props.returnResponse;
        }
        if (error instanceof TypeError) {
          if (error.message.includes('NetworkError')) {
            const _error = new APIError(error.message, Globals.CODES.NETWORK_ERROR);
            console.error('Network Error', error.message);
            const friendlyResponse = await APIService.ResponseErrorHandler(_error);
            props.returnResponse.message = friendlyResponse.message;
            props.returnResponse.header = friendlyResponse.header;
            props.returnResponse.errorCode = Globals.CODES.NETWORK_ERROR;
          } else {
            const _error = new APIError(error.message, Globals.CODES.TYPE_ERROR);
            const friendlyResponse = await APIService.ResponseErrorHandler(_error);
            props.returnResponse.message = error.message;
            props.returnResponse.header = friendlyResponse.header;
            props.returnResponse.errorCode = Globals.CODES.TYPE_ERROR;
          }
          return props.returnResponse;
        }
        console.error('Unknown error encountered', error);
        props.returnResponse.message = error;
      });
    return props.returnResponse;
  }

  /* *******************************************************************************************************************
                      Response Handler
  **********************************************************************************************************************/
    public static async ResponseErrorHandler (error: APIError): Promise<{ message: string, header: string }> {
    switch (error.code) {
    case -1:
        return { message: Globals.MESSAGES.UNKNOWN_ERROR, header: 'Error: -1' };
    case 500:
        return { message: error.message, header: 'Error: 500' };
    case 401: {
        return { message: error.message, header: 'Unauthorized: 401' };
    }
    case Globals.CODES.TOKEN_EXPIRED: {
        // Renew Token and try again
        return { message: error.message, header: 'Token Expired: ' + Globals.CODES.TOKEN_EXPIRED };
    }
    case Globals.CODES.NETWORK_ERROR: {
        return { message: error.message, header: 'Network Error: ' + Globals.CODES.NETWORK_ERROR };
    }
    case Globals.CODES.TYPE_ERROR: {
        return { message: error.message, header: 'TypeError: ' + Globals.CODES.TYPE_ERROR };
    }
    case Globals.CODES.CONFLICT_ERROR: {
        return { message: error.body ?? error.message, header: 'Conflict: ' + Globals.CODES.CONFLICT_ERROR };
    }
    default:
        return { message: error.message, header: 'Error' };
    }
    }
}